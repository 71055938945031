import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function EquipmentDetail() {
    const { id } = useParams();
    const [data, setData] = useState({});
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/equipment-id-entries-by-id/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                // console.log(res.data.data);
                // console.log(Object.keys(res.data.data[0]))
                setData(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);
    return (
        <>
            <div className='flex flex-col items-center justify-center mt-10 text-center'>
                {
                    Object.keys(data).length !== 0 ?
                        <>
                            <table className='w-[80%] border-2'>
                                <tr>
                                    <th className='border-2'>Date</th>
                                    <td className='border-2'>{new Date(data.date).toLocaleString() || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Intent of Trip</th>
                                    <td className='border-2'>{data.intent_of_trip || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Username</th>
                                    <td className='border-2'>{data.username || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Customer Name</th>
                                    <td className='border-2'>{data.customer_name || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>City</th>
                                    <td className='border-2'>{data.city || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>State</th>
                                    <td className='border-2'>{data.state || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Primary Contact</th>
                                    <td className='border-2'>{data.primary_contact || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Electrician Name</th>
                                    <td className='border-2'>{data.electrician_name || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Download or Install notes</th>
                                    <td className='border-2'>{data.download_or_install || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Cases Left</th>
                                    <td className='border-2'>{data.cases_left || ''}</td>
                                </tr>
                                <tr>
                                    <th className='border-2'>Data Integrity Check</th>
                                    <td className='border-2'>{data.data_integrity_check || ''}</td>
                                </tr>
                            </table>

                            {
                                data.qr_data.length ?
                                    <>
                                        <table className='w-[80%] border-2 mt-20 text-center'>
                                            <tr>
                                                <th className='border-2'>Meter#</th>
                                                <th className='border-2'>AP Equipment ID Number</th>
                                                <th className='border-2'>Image</th>
                                                <th className='border-2'>Notes</th>
                                                <th className='border-2'>Equipment Detail</th>
                                            </tr>
                                            {
                                                data.qr_data.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td className='border-2'>{item.meter_number}</td>
                                                        <td className='border-2'>{item.ap_equipment_id_number}</td>
                                                        <td className='border-2 flex items-center justify-center'>
                                                            {
                                                                item.image && <img src={item.image} className="h-[100px] w-[100px]" />

                                                            }
                                                        </td>
                                                        <td className='border-2'>{item.notes}</td>
                                                        <td className='border-2'>{item.compMakeModel}</td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </>
                                    :
                                    <></>
                            }
                        </>
                        :
                        <>
                        </>
                }
            </div>
        </>
    )
}