import React, { Component, useState, useEffect } from 'react';

import axios from 'axios';

import { MdDelete, MdEdit } from "react-icons/md";
import Dashboard from './Dashboard';
import EditInventoryType from '../Components/EditInventoryType';

const InventoryType = () => {

    const [meter_type, setMeter_type] = useState("");
    const   submit = () => {
        axios.post(`${process.env.REACT_APP_API_URL}admin/inventoryType/newInventoryType`, {
            meter_type: meter_type
        },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
                }
            }
        ).then((res) => {
            console.log(res);
            if (res) {
                setMeter_type("");
                alert("Inventory Type Added Successfully");
                window.location.reload();
            }
        }).catch((err) => {
            alert("Oops! something went wrong");
            console.log(err);
        })
    }

    const [inventoryType, setInventoryType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/inventoryType/getInventoryType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data) {
                console.log(res.data);
                setInventoryType(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const deleteInventoryType = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}admin/inventoryType/deleteInventoryType/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            console.log(res);
            if(res&&res.data){
                alert("Inventory Type Deleted Successfully");
                window.location.reload();
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <Dashboard>
            <div className='flex items-center justify-center mt-5 gap-2'>
                <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-9/12 p-2.5 "
                    placeholder='Add new equipment type'
                    value={meter_type}
                    onChange={(e) => { setMeter_type(e.target.value) }}
                />

                <p className='bg-white text-black text-lg font-bold hover:cursor-pointer' onClick={submit}>+</p>
            </div>
            <table className='w-full mt-5'>
                <tr>
                    <td className='font-bold'>Meter Type</td>
                    <td className='font-bold'>Edit</td>
                    <td className='font-bold'>Delete</td>
                </tr>
                {
                    inventoryType.length ?
                        <>
                            {
                                inventoryType.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{item.meter_type}</td>
                                            <td><EditInventoryType id={item._id}/></td>
                                            <td><MdDelete color='blue' onClick={()=>deleteInventoryType(item._id)} /></td>
                                        </tr>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                        </>
                }
            </table>
        </Dashboard>
    )
}

export default InventoryType;