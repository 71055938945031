import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from './Dashboard';
import { MdDelete } from "react-icons/md";

import { BiSort } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const EquipmentAdmin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/equipment-id-entries`, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
    //   }
    // })
    //   .then((res) => {
    //     setPages(res.data.data.totalPages);
    //     setCurrentPage(res.data.data.currentPage);
    //     setData(res.data.data.data);
    //     //console.log(res.data.user);
    //     setLoading(false);
    //   }).catch((err) => {
    //     console.log(err);
    //   })
    fetchData(1);
  }, []);

  const fetchData = (page) => {
    setIsLoadMore(true);
    axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/equipment-id-entries?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    })
      .then((res) => {
        if (page == 1) {
          setData(res.data.data.data);
        } else {
          setData(item => [...item, ...res.data.data.data]);
        }
        setPages(Number(res.data.data.totalPages));
        setCurrentPage(Number(res.data.data.currentPage));
        setIsLoadMore(false);
        //console.log(res.data.user);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
      })
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const getSearchTerm = (e) => {
    //searchHandler(e.target.value);
    searchData(e.target.value);
  };

  const searchHandler = (e) => {
    setSearchTerm(e);
    if (e.length !== "") {
      //console.log(e);
      const newPerformersList = data.filter((performer) => {
        //console.log(performer,e);
        //console.log(Object.values(performer.cases_left))
        return Object.values(performer)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResult(newPerformersList);
      //console.log(newPerformersList);
    } else {
      setSearchResult(data);
    }
  }

  const casesLeftHandler = (e) => {
    if (e.target.value === "Cases Left") {
      setSearchResult(data);
    }
    else {
      setSearchTerm(e.target.value)
      const newPerformersList = data.filter((performer) => {
        return Object.values(performer.cases_left)
          .join("")
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setSearchResult(newPerformersList);
      //console.log(searchTerm);
      //console.log(newPerformersList);
    }
  };

  const [filterDate, setFilterDate] = useState(false);
  const dateHandler = (e) => {
    if (filterDate !== true) {
      setSearchResult(data.reverse());
      setFilterDate(true);
    }
    else {
      setData(searchResult.reverse());
      setFilterDate(false);
    }
  }

  const deleteData = (e) => {
    axios.delete(`${process.env.REACT_APP_API_URL}equipmentIdForm/delete-equipment/${e}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    })
      .then((res) => {
        alert("Data Deleted Successfully");
        window.location.reload();
      })
      .catch((err) => {
        alert("Error while deleting Data");
      })
  }


  const searchData = (data) => {
    setSearchTerm(data);
    axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/search-equipment?query=${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    }).then((res) => {
      if (res && res.data && res.data.success) {
        setSearchResult(res.data.data);
      }
    }).catch(err => {

    })
  }
  return (
    <>
      <Dashboard>
        <div className='flex flex-col md:flex-row gap-4 justify-center items-center mt-10'>
          <input
            className='h-12 border-2 border-teal-600 rounded-lg text-center w-7/12'
            placeholder='Search Here'
            onChange={getSearchTerm}
          />
        </div>
        {/* <div>
          <div class="block items-center">
            <label
              class=" text-sm font-medium text-gray-900 dark:text-gray-400"
              for="IsAnonymous"
            >
              Cases Left
            </label>
            <select
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l block w-3/12 p-2.5 mt"
              name="IsAnonymous"
              onChange={casesLeftHandler}
              required
            >
              <option>Cases Left</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div> */}
        <div className="mt-8 relative overflow-x-auto shadow-md sm:rounded-lg h-[80vh]">
          <table className="w-full text-sm text-gray-500 text-center">
            <thead className="text-xs text-gray-700 bg-gray-50 sticky top-0 z-10">
              <tr className="text-base">
                <th scope="col" className="px-6 py-3 flex items-center justify-center">
                  Date <BiSort onClick={dateHandler} />
                </th>
                <th scope="col" className="px-6 py-3">
                  Technician Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Name
                </th>
                <th scope="col" className="px-6 py-3">
                  City
                </th>
                <th scope="col" className="px-6 py-3">
                  Primary Contact
                </th>
                <th scope="col" className="px-6 py-3">
                  Intent of Trip
                </th>
                <th scope="col" className="px-6 py-3">
                  Delete
                </th>
                <th scope="col" className="px-6 py-3">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {
                loading ?
                  <div className="text-center">
                    <svg
                      role="status"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                  :
                  <>
                    {
                      searchTerm.length < 1 || filterDate ?
                        data.map((item, idx) => {
                          return (
                            <tr className="bg-white border-b cursor-pointer" key={idx}>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                              >
                                {new Date(item.date).toLocaleString()}
                              </th>
                              <td className="px-6 py-4">{item.username}</td>
                              <td className="px-6 py-4">{item.customer_name}</td>
                              <td className="px-6 py-4">{item.city}</td>
                              <td className="px-6 py-4">{item.primary_contact}</td>
                              <td className="px-6 py-4">{item.intent_of_trip}</td>
                              <td className="px-6 py-4">
                                <button
                                  type="button"
                                  className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                  onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                >
                                  <MdDelete color='blue' />
                                </button>
                              </td>
                              <td className="px-6 py-4">
                                <p className='w-[100px] bg-teal-600 text-white p-[5px] rounded-lg' onClick={()=>{navigate(`/equipmentDetail/${item._id}`)}}>View Details</p>
                              </td>
                            </tr>
                          )
                        })

                        :

                        searchResult.map((item, idx) => {
                          return (
                            <tr className="bg-white border-b cursor-pointer" key={idx}>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                              >
                                {new Date(item.date).toLocaleString()}
                              </th>
                              <td className="px-6 py-4">{item.username}</td>
                              <td className="px-6 py-4">{item.customer_name}</td>
                              <td className="px-6 py-4">{item.city}</td>
                              <td className="px-6 py-4">{item.primary_contact}</td>
                              <td className="px-6 py-4">{item.intent_of_trip}</td>
                              <td className="px-6 py-4">
                                <button
                                  type="button"
                                  className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                  onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                >
                                  <MdDelete color='blue' />
                                </button>
                              </td>
                              <td className="px-6 py-4">
                                <p className='w-[100px] bg-teal-600 text-white p-[5px] rounded-lg' onClick={()=>{navigate(`/equipmentDetail/${item._id}`)}}>View Details</p>
                              </td>
                            </tr>
                          )
                        })
                    }
                  </>
              }
            </tbody>
          </table>
        </div>
        {/* <div className='flex justify-center gap-12 mt-10 mb-10'>
          {
            currentPage !== 1 ?
              <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage - 1) }}>Prev</button>
              :
              <></>
          }
          {
            currentPage !== pages ?
              <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage + 1) }}>Next</button>
              :
              <></>
          }
        </div> */}
         <div>
            {
              currentPage != pages &&
              <>
                {
                  !isLoadMore ?
                    <button className='text-center w-[100%] bg-teal-600 text-white' onClick={() => { fetchData(currentPage + 1) }}>
                      Load More
                    </button>
                    :
                    <div className="text-center">
                      <svg
                        role="status"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                }
              </>
            }
          </div>
      </Dashboard>
    </>
  )
}

export default EquipmentAdmin