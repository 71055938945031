import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { Disclosure } from "@headlessui/react";

export default function InventoryByCustomer() {
    const [equipmentData, setEquipmentData] = useState([]);
    const [active, setActive] = useState(true);

    const fetchData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-by-customer-name`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            // console.log(res.data);
            if (res && res.data.success) {
                // console.log(res.data.data);
                let resp = res.data.data;
                const desiredOrder = ['Columbus Storage', 'Chicago Storage', 'Arkansas Storage'];
                resp.sort((a, b) => {
                    const aIndex = desiredOrder.indexOf(a.customersName);
                    const bIndex = desiredOrder.indexOf(b.customersName);
                    if (aIndex === -1) return 1; // If not in desiredOrder, move to the end
                    if (bIndex === -1) return -1; // If not in desiredOrder, move to the end

                    return aIndex - bIndex;
                })
                // console.log(resp);
                setEquipmentData(resp);
                setActive(false);
            }
        }).catch(err => {
            console.log(err);
            alert("Oops! something went wrong");
            setActive(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const getSearchTerm = (e) => {
        searchHandler(e.target.value);
    };

    const searchHandler = (e) => {
        setSearchTerm(e);
        if (e.length !== "") {
            //console.log(e);
            const newPerformersList = equipmentData.filter((performer) => {
                return Object.values(performer)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            });
            setSearchResult(newPerformersList);
            //console.log(newPerformersList);
        } else {
            setSearchResult(equipmentData);
        }
    }
    return (
        <>
            <div className="w-full h-[6vh] text-white bg-teal-600 flex flex-col justify-center px-2">
                <p className="text-[20px]">Inventory</p>
            </div>
            <div className="flex flex-col items-center mt-5">
                <input
                    className='h-12 border-2 border-teal-600 rounded-lg text-center w-5/12'
                    placeholder='Search Here'
                    onChange={getSearchTerm}
                />
            </div>

            {
                !active ?
                    <div className="w-full px-4">
                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                            {
                                searchTerm.length <= 1 ?
                                    <>
                                        {
                                            equipmentData.map((item, idx) => (
                                                <Disclosure key={idx} as="div" className="mt-2">
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button className="flex flex-row w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75">
                                                                <p>{item.customersName}</p>
                                                                <p>{item.count}</p>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                                <table className="w-full border-2 text-sm text-left text-gray-500">
                                                                    <thead className=" border-2">
                                                                        <tr className=" border-2">
                                                                            <th scope="col" className="px-3 py-2 border-2">
                                                                                Meter Type
                                                                            </th>

                                                                            <th scope="col" className="px-3 py-2 border-2">
                                                                                Count
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            item.data.map((item2, idx2) => (
                                                                                <tr className="bg-white border-2 " key={idx2}>
                                                                                    <td
                                                                                        scope="row"
                                                                                        className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                                                                                    >
                                                                                        {item2.key}
                                                                                    </td>
                                                                                    <td
                                                                                        scope="row"
                                                                                        className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                                                                                    >
                                                                                        {item2.value}
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            searchResult.map((item, idx) => (
                                                <Disclosure key={idx} as="div" className="mt-2">
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button className="flex flex-row w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75">
                                                                <p>{item.customersName}</p>
                                                                <p>{item.count}</p>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                                <table className="w-full border-2 text-sm text-left text-gray-500">
                                                                    <thead className=" border-2">
                                                                        <tr className=" border-2">
                                                                            <th scope="col" className="px-3 py-2 border-2">
                                                                                Meter Type
                                                                            </th>

                                                                            <th scope="col" className="px-3 py-2 border-2">
                                                                                Count
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            item.data.map((item2, idx2) => (
                                                                                <tr className="bg-white border-2 " key={idx2}>
                                                                                    <td
                                                                                        scope="row"
                                                                                        className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                                                                                    >
                                                                                        {item2.key}
                                                                                    </td>
                                                                                    <td
                                                                                        scope="row"
                                                                                        className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                                                                                    >
                                                                                        {item2.value}
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ))
                                        }
                                    </>
                            }
                        </div>
                    </div>
                    :
                    <div className="text-center">
                        <svg
                            role="status"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
            }
        </>
    )
}