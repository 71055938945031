import React, { useState, useEffect } from "react";
import { BsFillCameraFill, BsFillTrashFill } from "react-icons/bs";
import { BiEdit, BiSolidEditAlt, BiCloudUpload } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";

const CheckoutForm = () => {
    const navigate = useNavigate();
    const d = new Date().toISOString;
    //alert('this page is in process')
    const username = localStorage.getItem("apUsername");

    const date1 = new Date().toLocaleDateString();
    const time1 = new Date().toLocaleTimeString();

    const [todos, setTodos] = useState([]);
    const [meter, setMeter] = useState("");


    const [date, setDate] = useState(new Date());
    const [customer_name, setCustomer_name] = useState("");
    const [checkOutTo, setCheckOutTo] = useState("");
    const [city, setCity] = useState("");

    // post api of equipmentID form
    const Submit = () => {
        let data = {
            todos, date, customer_name, city, checkOutTo
        }
        console.log(data);

        axios.post(`${process.env.REACT_APP_API_URL}inventoryManagement/checkout-form`, {
            date: date,
            customer_name: customer_name,
            checkOutTo: checkOutTo,
            city: city,
            todos: todos
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            console.log(res);
            if (res && res.data.success) {
                alert(res.data.message);
            }
            else {
                alert("Oops! Something went wrong");
            }
        }).catch((err) => {
            alert("Oops! Something went wrong");
            console.log(err);
        })
        // axios.post(`${process.env.REACT_APP_API_URL}equipment/equipment-id-form`, {

        //     date: date,
        //     intent_of_trip: intend_of_trip,
        //     username: technician_name,
        //     customer_name: customer_name,
        //     city: city,
        //     state: state,
        //     primary_contact: primary_contact,
        //     electrician_name: electrician_name,
        //     download_or_install: download_or_install,
        //     cases_left: cases_left,
        //     data_integrity_check: dataIntegrityChecked,
        //     qr_data: JSON.stringify(todos)
        // },
        //     {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
        //         }
        //     }
        // ).then((res) => {
        //     console.log(res);
        //     alert("Form Submitted Successfully");
        //     navigate("/home/equipment");

        // }).catch((err) => {
        //     alert("Oops! Something went wrong");
        //     console.log(err);
        // })
    }



    const addTodo = () => {
        let x = Math.random();
        const newTodo = {
            id: x,
            meter_number: meter,
        };

        if (!edit) {
            setTodos([...todos, newTodo]);
        } else {
            const updatedData = todos.map(item => {
                if (item.id === editId) {
                    return { ...item, meter_number: meter };
                }
                return item;
            })
            setTodos(updatedData);
            // console.log(todos);
            setEditId(0);
            setEdit(false);
        }
        // setTodos([...todos, newTodo]);
        setMeter('');
    }


    const deleteTodo = (id) => {
        const updatedData = todos.filter((item) => item.id !== id);
        setTodos(updatedData);
    }
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);

    const editTodo = (id) => {
        if (!edit) {
            setEditId(item => id);
            setEdit(true);
            let index = todos.findIndex(item => item.id === id);
            setMeter(todos[index].meter_number);
        } else {
            alert("One Edit at a time");
        }
        // const updatedData = todos.filter((item) => item.id !== id);
        // setTodos(updatedData);
    }

    const [assignedToDropdown, setAssignedToDropDown] = useState([]);

    useEffect(() => {
        fetchData(1);
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}apEnergy/usernames`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                // console.log(res.data.user);
                setAssignedToDropDown(res.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const [data, setData] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadMore, setIsLoadMore] = useState(false);

    const fetchData = async (page) => {
        setIsLoadMore(true);
        await axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/checkout-data?page=${page}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            //console.log(res.data.data);
            if (res && res.data && res.data.success) {
                if(page == 1){
                    setData(res.data.data.data);
                } else {
                    setData(item=>[...item, ...res.data.data.data]);
                }
                setPages(Number(res.data.data.totalPages));
                setCurrentPage(Number(res.data.data.currentPage));
                setIsLoadMore(false);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <Dashboard>
            {/* <div className="w-full h-[6vh] text-white bg-teal-600 flex flex-col justify-center px-2">
                <p className="text-[20px]">New Equipment Id Form</p>
            </div> */}
            {/* form */}


            {/* form for large devices */}

            <div className="hidden lg:block">
                <div className="px-6 flex flex-col lg:flex-row  lg:justify-evenly">
                    <table className="mt-8">
                        <tr className="text-lg">
                            <td className="px-6 py-3">
                                {" "}
                                <label
                                    for="Date"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Date:
                                </label>{" "}
                            </td>
                            <td className="px-6 py-3">
                                {new Date(date).toLocaleString()}
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="customer name"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Checked out to:
                                </label>
                            </td>

                            <td className="px-6 py-3">

                                <select
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    name="IsAnonymous"
                                    onChange={(e) => { setCheckOutTo(e.target.value) }}
                                    required
                                >
                                    <option>select</option>
                                    {
                                        assignedToDropdown && assignedToDropdown.length ?
                                            <>
                                                {
                                                    assignedToDropdown.map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </select>
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="customer name"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Customer Name:
                                </label>
                            </td>

                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    onChange={(e) => { setCustomer_name(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="city"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    City:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    onChange={(e) => { setCity(e.target.value) }}
                                />
                            </td>
                        </tr>
                    </table>

                    <div>
                        {
                            todos.length ?
                                <>


                                    <table className="w-full border-2 text-sm text-left text-gray-500">
                                        <thead className=" border-2">
                                            <tr className=" border-2">
                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Meter #
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {todos.map((item, idx) => {
                                                return (
                                                    <tr className="bg-white border-2 " key={idx}>
                                                        <td
                                                            scope="row"
                                                            className="px-3 py-2 border-2 font-medium text-gray-900 text-center"
                                                        >
                                                            {item.meter_number}
                                                        </td>

                                                        <td className="px-3 py-2 flex flex-row justify-center items-center gap-4">
                                                            <BiEdit size={20} className="hover:cursor-pointer" onClick={() => { editTodo(item.id) }} />
                                                            <MdDelete size={20} className="hover:cursor-pointer" onClick={() => { deleteTodo(item.id) }} />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>


                                </>

                                :
                                <div></div>
                        }

                        <table className="mt-8">
                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Meter#{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    <input
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
                                        value={meter}
                                        onChange={(e) => { setMeter(e.target.value) }}
                                    />
                                </td>
                            </tr>
                            <tr >
                                <td className="px-6 py-3">
                                    <button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32" onClick={addTodo}>Add more</button>
                                </td>

                            </tr>
                        </table>
                    </div>


                </div>

                <div className="flex flex-row items-center justify-center">
                    <div className="px-10 my-10">
                        <button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32"
                            onClick={Submit}
                        >Save All</button>
                    </div>

                    {/* <div className="px-10 my-10">
<a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@apenergy.com&su=EquipmentID Form details%20Email&body=&atturl=window.print()">
<button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32"
>Send Email </button>
</a>
</div> */}
                </div>
            </div>


            {/* form for small devices */}

            <div className="lg:hidden">
                <div className="flex flex-col items-center gap-4 px-10">

                    <div className="flex flex-row items-center justify-between w-full md:w-1/2 mt-5">
                        <label className='text-base'>Date: </label>
                        <p className="text-center">{new Date(date).toLocaleString()}</p>
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Checkout To: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setCheckOutTo(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Customer Name: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setCustomer_name(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>City: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setCity(e.target.value) }} />
                    </div>

                    {
                        todos.length !== 0 ?
                            <>
                                <table className="w-[90%] text-left text-gray-500" style={{ border: '5px solid black' }}>
                                    <thead className=" border-2">
                                        <tr className=" border-2">
                                            <th scope="col" className="px-3 py-2 border-2 w-1/5 text-[10px] text-center">
                                                Meter#
                                            </th>

                                            <th scope="col" className="px-3 py-2 border-2 w-1/5 text-[10px] text-center">
                                                Edit/Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {todos.map((item, idx) => {
                                            return (
                                                <tr className="bg-white border-2 " key={idx}>
                                                    <td
                                                        scope="row"
                                                        className="px-3 py-2 border-2 font-medium text-gray-900 w-1/5 text-[10px] text-center"
                                                    >
                                                        {item.meter_number}
                                                    </td>

                                                    <td className="px-3 border-2 py-2 w-1/5 text-[10px] text-center">
                                                        <div className="flex gap-4">
                                                            <BiEdit size={20} className="hover:cursor-pointer" onClick={() => { editTodo(item.id) }} />
                                                            <MdDelete size={20} className="hover:cursor-pointer" onClick={() => { deleteTodo(item.id) }} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </>
                            :
                            <>

                            </>
                    }

                    <div className="w-full border-black flex flex-col justify-center items-center" style={{ border: '5px solid black' }}>

                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2">
                                Meter#
                            </label>
                            <input type="text" className='rounded-md border-gray-400 h-8 focus:ring-teal-600 w-11/12 mb-10' value={meter} onChange={(e) => { setMeter(e.target.value) }} />
                        </div>
                    </div>

                    <div className="w-full mb-4">
                        <button className="w-5/12 bg-teal-600 text-white h-12 px-2 py-1" onClick={addTodo}>Add</button>
                        <button className="w-2/12 text-white h-12 px-2 py-1"></button>
                        <button className="w-5/12 bg-teal-600 text-white h-12 px-2 py-1" onClick={Submit}>Save Form</button>
                    </div>
                </div>
            </div>

            <div className="checkoutData">
                <div className="mt-8 relative overflow-x-auto shadow-md sm:rounded-lg h-[80vh]">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50 sticky top-0 z-10">
                            <tr className="text-base text-center text-[11px]">
                                <th scope="col" className="px-3 py-3">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Checked out to
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    City
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Meters
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length ?
                                    <>
                                        {
                                            data.map((item, idx) => (
                                                <tr className="bg-white border-b text-center cursor-pointer text-[11px]" key={idx}>
                                                    <td className="px-3 py-4">{item.customer_name}</td>
                                                    <td className="px-3 py-4">{item.assigned_to}</td>
                                                    <td className="px-3 py-4">{item.city}</td>
                                                    <td className="px-3 py-4">{new Date(item.date).toLocaleString()}</td>
                                                    <td className="px-3 py-4">
                                                        <ul>
                                                            {
                                                                item.meters && item.meters.length ?
                                                                    <>
                                                                        {
                                                                            item.meters.map((item2, idx2) => (
                                                                                <li key={idx2}>
                                                                                    {item2.meter_number}
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>

                                                                    </>
                                                            }
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </>
                                    :
                                    <>

                                    </>
                            }
                        </tbody>
                    </table>
                </div>

                {/* <div className='flex justify-center gap-12 mt-10 mb-10'>
                    {
                        currentPage !== 1 ?
                            <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage - 1) }}>Prev</button>
                            :
                            <></>
                    }
                    {
                        currentPage !== pages ?
                            <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage + 1) }}>Next</button>
                            :
                            <></>
                    }
                </div> */}

                <div>
                    {
                        currentPage != pages &&
                        <>
                            {
                                !isLoadMore ?
                                    <button className='text-center w-[100%] bg-teal-600 text-white' onClick={() => { fetchData(currentPage + 1) }}>
                                        Load More
                                    </button>
                                    :
                                    <div className="text-center">
                                        <svg
                                            role="status"
                                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                    </div>
                            }
                        </>
                    }
                </div>
            </div>
        </Dashboard >
    );
};

export default CheckoutForm;
