import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";


function AddAccount(props) {
  let [isOpen, setIsOpen] = useState(false);

  //add new
  function closeModal() {
    window.location.reload();
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const[name,setName]=useState("");
  const[email,setEmail]=useState("");
  const[username,setusername]=useState("");
  const[password,setPassword]=useState("");
  const[role,setRole] = useState("Select");

  const updateData=()=>{
    if(role == 'Select'){
      alert('Select Role');
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}apEnergy/signup`,{
        name:name,
        email:email,
        username:username,
        password:password,
        role: role
    },{
      headers:{
          Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
  }).then((res)=>{
        console.log(res);
        closeModal();
    }).catch((err)=>{
        console.log(err);
    })
  }
  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-40"
        >
         Add New
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add new User
                  </Dialog.Title>
                  <div className="mt-2">
                      <div class="grid gap-6 mb-6 lg:grid-cols-2">
                        <div>
                          <label
                            for="name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            onChange={(e)=>{setName(e.target.value)}}
                          />
                        </div>
                        <div>
                          <label
                            for="email"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                            
                            onChange={(e)=>{setEmail(e.target.value)}}
                          />
                        </div>
                        <div>
                          <label
                            for="username"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            id="username"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                            
                            onChange={(e)=>{setusername(e.target.value)}}
                            
                          />
                        </div>
                        <div>
                          <label
                            for="first_name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            
                            onChange={(e)=>{setPassword(e.target.value)}}
                          />
                        </div>
                        <div>
                          <label
                            for="first_name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Role
                          </label>
                          <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="Select"
                            onChange={(e) => { setRole(e.target.value) }}

                          >
                            <option>Select</option>
                            <option value='user'>User</option>
                            <option value='admin'>Admin</option>
                          </select>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                        // onClick={closeModal}
                        onClick={updateData}
                      >
                        Add
                      </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AddAccount;