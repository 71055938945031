import React, { Fragment, useEffect, useState } from 'react';

import { Dialog, Transition } from "@headlessui/react";

import Dashboard from './Dashboard';

import axios from "axios";
import AddAccount from '../Components/AddAccount';
import { MdDelete, MdEdit } from "react-icons/md";

const UserAdmin = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  useEffect(() => {
    getData(1);
  }, []);

  const getData = (page) => {
    setIsLoadMore(true);
    axios.get(`${process.env.REACT_APP_API_URL}apEnergy/users_information?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    })
      .then((res) => {
        if (res.data.data && res.data.data.data && res.data.data.data.length) {
          if (page == 1) {
            setData(res.data.data.data);
          } else {
            setData(item => [...item, ...res.data.data.data]);
          }
          setCurrentPage(Number(res.data.data.currentPage));
          setTotalPages(Number(res.data.data.totalPages));
          setIsLoadMore(false);
          console.log(res.data.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const getSearchTerm = (e) => {
    searchHandler(e.target.value);
  };

  const searchHandler = (e) => {
    setSearchTerm(e);
    if (e.length !== "") {
      //console.log(e);
      const newPerformersList = data.filter((performer) => {
        return Object.values(performer)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      });
      setSearchResult(newPerformersList);
      //console.log(newPerformersList);
    } else {
      setSearchResult(data);
    }
  }

  const deleteData = (e) => {
    //console.log(e);
    axios.delete(`${process.env.REACT_APP_API_URL}apEnergy/delete_user/${e}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    })
      .then((res) => {
        alert("Data Deleted Successfully");
        window.location.reload();
      })
      .catch((err) => {
        alert("Error while deleting Data");
      })
  }

  let [isOpen, setIsOpen] = useState(false);

  //add new
  function closeModal() {
    // window.location.reload();
    getData(1);
    setMyData([]);
    setIsOpen(false);
  }

  function openModal(id) {
    setIsOpen(true);
    getDataById(id);
  }

  const [myData, setMyData] = useState([]);

  const getDataById = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}apEnergy/user_details/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    })
      .then((res) => {
        console.log(res);
        setMyData(res.data.data);
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setusername(res.data.data.username);
        setPassword(res.data.data.password);
        setRole(res.data.data.role);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("Select");

  const updateData = (id) => {
    if(role == 'Select'){
      alert('Select Role');
      return;
    }
    axios.put(`${process.env.REACT_APP_API_URL}apEnergy/user_details_edit/${id}`, {
      _id: id,
      name: name,
      email: email,
      username: username,
      password: password,
      role: role
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    }).then((res) => {
      console.log(res);
      closeModal();
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <>
      <Dashboard>
        <div className='flex flex-col md:flex-row gap-4 justify-center items-center mt-10'>
          <input
            className='h-12 border-2 border-teal-600 rounded-lg text-center w-7/12'
            placeholder='Search Here'
            onChange={getSearchTerm}
          />

          <AddAccount />
        </div>

        <div className="mt-8 relative overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg h-[80vh]">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-50 sticky top-0 z-10">
              <tr className="text-base">
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Username
                </th>
                <th scope="col" className="px-6 py-3">
                  Password
                </th>
                <th scope="col" className="px-6 py-3">
                  Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Edit
                </th>
                <th scope="col" className="px-6 py-3">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className='overflow-y-auto'>
              {
                loading ?
                  <div className="text-center">
                    <svg
                      role="status"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                  :
                  <>
                    {
                      searchTerm.length < 1 ?
                        data.map((item, idx) => {
                          return (
                            <tr className="bg-white border-b " key={idx}>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                              >
                                {item.name}
                              </th>
                              <td className="px-6 py-4">{item.email}</td>
                              <td className="px-6 py-4">{item.username}</td>
                              <td className="px-6 py-4">{item.password}</td>
                              <td className="px-6 py-4">{item.role}</td>
                              <td className="px-6 py-4">
                                <div className="mt-4">
                                  <button
                                    type="button"
                                    onClick={() => { openModal(item._id) }}
                                    className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  >
                                    <MdEdit color='blue' />
                                  </button>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <button
                                  type="button"
                                  className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                  onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                >
                                  <MdDelete color='blue' />
                                </button>
                              </td>
                            </tr>
                          )
                        })

                        :

                        searchResult.map((item, idx) => {
                          return (
                            <tr className="bg-white border-b " key={idx}>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                              >
                                {item.name}
                              </th>
                              <td className="px-6 py-4">{item.email}</td>
                              <td className="px-6 py-4">{item.username}</td>
                              <td className="px-6 py-4">{item.password}</td>
                              <td className="px-6 py-4">
                                <div className="mt-4">
                                  <button
                                    type="button"
                                    onClick={() => { openModal(item._id) }}
                                    className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  >
                                    <MdEdit color='blue' />
                                  </button>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <button
                                  type="button"
                                  className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                  onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                >
                                  <MdDelete color='blue' />
                                </button>
                              </td>
                            </tr>
                          )
                        })
                    }
                  </>
              }
            </tbody>
          </table>
          <div>
            {
              currentPage != totalPages &&
              <>
                {
                  !isLoadMore ?
                    <button className='text-center w-[100%] bg-teal-600 text-white' onClick={() => { getData(currentPage + 1) }}>
                      Load More
                    </button>
                    :
                    <div className="text-center">
                      <svg
                        role="status"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                }
              </>
            }
          </div>
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit User Information
                    </Dialog.Title>
                    <div className="mt-2">
                      <div class="grid gap-6 mb-6 lg:grid-cols-2">
                        <div>
                          <label
                            for="name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="name"
                            defaultValue={myData.name}
                            onChange={(e) => { setName(e.target.value) }}
                          />
                        </div>
                        <div>
                          <label
                            for="email"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                            placeholder="email"
                            defaultValue={myData.email}
                            onChange={(e) => { setEmail(e.target.value) }}
                          />
                        </div>
                        <div>
                          <label
                            for="username"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            id="username"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                            placeholder="username"
                            value={myData.username}
                            onChange={(e) => { setusername(e.target.value) }}
                            readOnly
                          />
                        </div>
                        <div>
                          <label
                            for="first_name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="password"
                            defaultValue={myData.password}
                            onChange={(e) => { setPassword(e.target.value) }}
                          />
                        </div>
                        <div>
                          <label
                            for=""
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Role
                          </label>
                          <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="Select"
                            value={role}
                            onChange={(e) => { setRole(e.target.value) }}

                          >
                            <option>Select</option>
                            <option value='user'>User</option>
                            <option value='admin'>Admin</option>
                          </select>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                        // onClick={closeModal}
                        onClick={() => { updateData(myData._id) }}
                      >
                        Update
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </Dashboard>
    </>
  )
}

export default UserAdmin