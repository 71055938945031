import React, { useState } from 'react';

import { GiHamburgerMenu } from "react-icons/gi";
import { BiUser } from "react-icons/bi";
import { AiOutlineForm } from "react-icons/ai";
import { MdOutlineInventory, MdManageAccounts, MdLogout } from "react-icons/md";
import { NavLink, useNavigate } from 'react-router-dom';


export default function Sidebar() {
    const navigate = useNavigate();
    const [isDisable, setIsDisable] = useState(false);
    const logout = () => {
        localStorage.clear();
        navigate("/");
    }

    const activeLink =
        "flex items-center gap-1 bg-white text-teal-700 px-4 rounded";

    const normalLink =
        "flex items-center gap-1";



    return (
        <>
            <div className='md:hidden'>
                <div className='bg-teal-600 w-full h-16 py-3 px-2'>
                    <GiHamburgerMenu className='text-white text-3xl hover:cursor-pointer' onClick={() => { setIsDisable(!isDisable) }} />
                </div>
                {
                    isDisable ?
                        <div className='border-2 border-gray-400 border-solid w-5/12 p-2 divide-y-2'>
                            {/* {
                                localStorage.getItem("adminType") === "super-admin" ?
                                    <NavLink to="/admin">
                                        <div className='flex items-center gap-1'>
                                            <span><BiUser /></span>
                                            <p>Admin</p>
                                        </div>
                                    </NavLink>
                                    :
                                    <>

                                    </>
                            } */}
                            <NavLink to="/userAdmin">
                                <div className='flex items-center gap-1'>
                                    <span><BiUser /></span>
                                    <p>Users</p>
                                </div>
                            </NavLink>
                            <NavLink to="/equipmentAdmin">
                                <div className='flex items-center gap-1'>
                                    <span><AiOutlineForm /></span>
                                    <p>Equipment</p>
                                </div>
                            </NavLink>
                            <NavLink to="/inventoryAdmin">
                                <div className='flex items-center gap-1'>
                                    <span><MdOutlineInventory /></span>
                                    <p>Inventory</p>
                                </div>
                            </NavLink>
                            <NavLink to='/adminEquipmentManagementNew'>
                                {/* <span><MdManageAccounts /></span> */}
                                <div className='flex items-center gap-1'>
                                    <span>Admin Inventory Management</span>
                                </div>
                            </NavLink>
                            <NavLink to='/checkoutForm'>
                                {/* <span><MdManageAccounts /></span> */}
                                <div className='flex items-center gap-1'>
                                    <span>Checkout Form</span>
                                </div>
                            </NavLink>
                            {/* <div className='flex items-center gap-1'>
                                <span><MdManageAccounts /></span>
                                <p>Accounts</p>
                            </div> */}
                            <button onClick={logout}>
                                <div className='flex items-center gap-1'>
                                    <span><MdLogout /></span>
                                    <p>Logout</p>
                                </div>
                            </button>
                        </div>
                        :
                        <div></div>
                }
            </div>

            {/* large device */}
            <div className='hidden w-full bg-teal-600 h-screen text-white md:flex flex-col items-center justify-center md:gap-8 lg:gap-10 text-sm font-medium text-center'>

                {/* {
                    localStorage.getItem("adminType") === "super-admin" ?
                        <NavLink to="/admin" className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                        }  >

                            <span><BiUser /></span>
                            <p>Admin</p>

                        </NavLink>

                        :

                        <>
                        </>
                } */}

                <NavLink to="/userAdmin" className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                }  >

                    <span><BiUser /></span>
                    <p>Users</p>

                </NavLink>
                <NavLink to="/equipmentAdmin" className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                } >

                    <span><AiOutlineForm /></span>
                    <p>Equipment</p>

                </NavLink>
                <NavLink to="/inventoryAdmin" className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                } >

                    <span><MdOutlineInventory /></span>
                    <p>Inventory</p>

                </NavLink>
                <NavLink to='/adminEquipmentManagementNew' className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                }>
                    {/* <span><MdManageAccounts /></span> */}
                    <p>Admin Inventory Management</p>
                </NavLink>
                <NavLink to='/checkoutForm' className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                }>
                    {/* <span><MdManageAccounts /></span> */}
                    <div className='flex items-center gap-1'>
                        <span>Checkout Form</span>
                    </div>
                </NavLink>
                <button onClick={logout}>
                    {/* <div className='flex items-center gap-1'> */}
                    <span><MdLogout /></span>
                    <p>Logout</p>
                    {/* </div> */}
                </button>
            </div>
        </>
    )
}