import React, { useEffect } from 'react';
import splash from "../assets/splash.png"

import Sidebar from '../Components/SideBar';

export default function Dashboard(props) {
    return (
        <>
            <div className='md:flex w-full gap-10'>
                <div className='md:w-1/12'>
                    <Sidebar/>
                </div>
                <div className='md:w-11/12'>
                    {
                        props.children
                        ||
                        <div className='w-full h-full flex flex-col'>
                        <img src={splash} className='w-80 h-80 m-auto' alt="logo"/>
                    </div> 
                    }
                </div>
            </div>
        </>
    )
}