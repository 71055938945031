import { Routes, Route, Navigate } from "react-router-dom";

import AdminLogin from "./Screens/AdminLogin";
import Dashboard from "./Screens/Dashboard";
import UserAdmin from "./Screens/UserAdmin";
import EquipmentAdmin from "./Screens/EquipmentAdmin";
import InventoryAdmin from "./Screens/InventoryAdmin";
import Admin from "./Screens/Admin";
import NotFound from "./NotFound";
import InventoryType from "./Screens/InventoryType";
import InventoryByCustomer from "./Screens/InventoryByCustomer";
import AdminEquipmentManagement from "./Screens/AdminEquipmentManagement";
import AdminEquipmentManagementNew from "./Screens/AdminEquipmentManagementNew";
import InventoryDetail from "./Screens/InventoryDetail";
import EquipmentDetail from "./Screens/EquipmentDetail";
import AdminNoteDetails from "./Screens/AdminNotesDetails";
import CheckoutForm from "./Screens/CheckoutForm";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<AdminLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/userAdmin" element={<UserAdmin />} />
        <Route path="/equipmentAdmin" element={<EquipmentAdmin />} />
        <Route path="/inventoryAdmin" element={<InventoryAdmin />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/inventoryType" element={<InventoryType />} />
        <Route path="/inventoryByCustomer" element={<InventoryByCustomer />} />
        <Route path="/adminEquipmentManagement" element={<AdminEquipmentManagement />} />
        <Route path="/adminEquipmentManagementNew" element={<AdminEquipmentManagementNew />} />
        <Route path="/adminNotesDetails/:customer_name/:city" element={<AdminNoteDetails />} />
        <Route path="/inventoryDetail/:id" element={<InventoryDetail />} />
        <Route path="/equipmentDetail/:id" element={<EquipmentDetail />} />
        <Route path="/checkoutForm" element={<CheckoutForm />} />
        <Route path='*' element={<NotFound />} />

        {/* <Route path="/superAdmin" element={<SuperAdminLogin />} /> */}

        {
          // localStorage.getItem("apEnergyAdminToken") ?
          //   <>
          //     <Route path="/superAdminDashboard" element={<SuperAdminDashboard />} />
          //     <Route path="/userSuperAdmin" element={<UserSuperAdmin />} />
          //     <Route path="/superAdmin/admin" element={<Admins />} />
          //     <Route path="/superAdminEquipment" element={<SuperAdminEquipment />} />
          //     <Route path="/superAdminInventory" element={<SuperAdminInventory />} />
          //     {/* <Route path='*' element={<NotFound />} /> */}
          //   </>
          //   :
          //   <Route render={() => { <Navigate to="/" /> }} />
        }
      </Routes>
    </>
  );
}

export default App;
