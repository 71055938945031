import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function InventoryDetail() {
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/view-location-history/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then(res => {
            console.log(res.data.data);
            if (res && res.data && res.data.success) {
                setData(res.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return (
        <>
            <div className='flex justify-center items-center mt-10'>
                <table className='w-[90%] border-2 text-center'>
                    <tr className='w-[50%]'>
                        <th className='border-2'>Date</th>
                        <th className='border-2'>Location</th>
                    </tr>
                    {
                        data.length !== 0 ?
                            <>
                                {
                                    data.record.map((item, idx) => (
                                        <tr className='w-[50%]' key={idx}>
                                            <td className='border-2'>{new Date(item.date).toLocaleString()}</td>
                                            <td className='border-2'>{item.location}</td>
                                        </tr>
                                    ))
                                }
                            </>
                            :
                            <></>
                    }
                </table>
            </div>
        </>
    )
}