import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";

import {MdEdit} from "react-icons/md";

function EditInventoryType(props) {
  let [isOpen, setIsOpen] = useState(false);

  //add new
  function closeModal() {
    window.location.reload();
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const[data,setData]=useState([]);

  const[inventoryType,setInventoryType] = useState("");
  const updateData=()=>{
    axios.put(`${process.env.REACT_APP_API_URL}admin/inventoryType/editInventoryType/${props.id}`,{
        meter_type: inventoryType
    },{
      headers:{
        Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
      }
    }).then((res)=>{
        console.log(res);
        closeModal();
    }).catch((err)=>{
        console.log(err);
    })
  }
  return (
    <>
      <div className="mt-4">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
         <MdEdit color='blue'/>
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Update Inventory Type
                  </Dialog.Title>
                  <div className="mt-2">
                      <div class="grid gap-6 mb-6 lg:grid-cols-2">
                        <div>
                          <label
                            for="name"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            InventoryType
                          </label>
                          <input
                            type="text"
                            id="name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="name"
                            value={inventoryType}
                            onChange={(e)=>{setInventoryType(e.target.value)}}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                        // onClick={closeModal}
                        onClick={updateData}
                      >
                        Update
                      </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default EditInventoryType;