import React, { useState } from 'react';

import logo from '../assets/logo.png';
import blog from '../assets/blog.png';
import fb from '../assets/fb.png';
import instagram from '../assets/instagram.png';
import linkedin from '../assets/linkedin.png';
import splash from '../assets/splash.png';

import axios from 'axios';

import { NavLink, useNavigate } from 'react-router-dom';

const AdminLogin = () => {

    const navigate = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const submit = async () => {
        //console.log("hello", process.env.REACT_APP_API_URL)
        localStorage.clear();
        axios.post(`${process.env.REACT_APP_API_URL}apEnergy/signin`, {
            username: userName,
            password: password
        }).then((res) => {
            console.log(res);
            if (res.data.data && res.data.data.auth && (res.data.data.role == 'admin' || res.data.data.role == 'super-admin')) {
               // history.replace("/");
               localStorage.setItem("apEnergyAdminToken",res.data.data.auth);
               localStorage.setItem("adminType",res.data.data.role);
               setLoading(!loading);
               navigate("/dashboard");
            }
            else{
                alert("Invalid Credentials")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (

        <>
            <div className='flex flex-row justify-center items-center'>
                <div className='hidden lg:block bg-gray-100 w-full h-screen '>
                    <div className='w-full h-full flex'>
                        <img src={splash} className='w-80 h-80 m-auto' alt="logo"/>
                    </div>
                </div>


                {/* form */}
                <div className='p-10 flex flex-col items-center w-full h-screen'>

                    <div className='lg:hidden'>
                        <img src={logo} className='w-48 h-20 lg:w-56 lg:h-24' alt="logo"/>
                    </div>

                    <h1 className='text-teal-600 font-bold my-10 text-3xl'>Admin Login</h1>

                    <div className='flex flex-col items-center gap-4'>

                        <div>
                            <label className='text-base'>Username</label>
                            <br />
                            <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setUserName(e.target.value) }} />
                        </div>

                        <div>
                            <label className='text-base'>Password</label>
                            <br />
                            <input type="password" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setPassword(e.target.value) }} />
                        </div>


                        {/* <NavLink to="/superAdmin" className='text-teal-600 font-medium text-left'>Super Admin Login?</NavLink> */}

                        <button className='mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-40' onClick={submit}> {loading?  <svg
                      role="status"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>  : 'Login'} </button>

                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <img src={blog} className="w-10 h-10" alt="logo"/>
                            <img src={fb} className="w-10 h-10" alt="logo"/>
                            <img src={instagram} className="w-10 h-10" alt="logo"/>
                            <img src={linkedin} className="w-10 h-10" alt="logo"/>
                        </div>
                    </div>


                </div>

            </div>


        </>


    )
}

export default AdminLogin;