import React, { Component, useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import Sidebar from '../Components/SideBar';
import Dashboard from './Dashboard';
import axios from 'axios';
import { MdEdit } from 'react-icons/md';

export default function AdminEquipmentManagement() {
    const [inventoryType, setInventoryType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}admin/inventoryType/getInventoryType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data) {
                // console.log(res.data);
                setInventoryType(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })

        fetchData('false');
    }, []);

    const [data, setData] = useState([]);

    const fetchData = (type) => {
        setData([]);
        axios.get(`${process.env.REACT_APP_API_URL}inventory/get-inventory?is_hidden=${type}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            const response = res.data;
            if (response && response.success) {
                let resp = response.data.sort((a, b) => a.customerName.localeCompare(b.customerName));
                // console.log(resp);
                setData(resp);
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const [notes, setNotes] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [city, setCity] = useState('');
    const [date, setDate] = useState('');
    const [recentDownload, setRecentDownload] = useState('');
    const [recentDownloadStaff, setRecentDownloadStaff] = useState('');
    const [hidden, setHidden] = useState(false);
    // const [nextDownload, setNextDownload] = useState('');
    // const [nextDownloadStaff, setNextDownloadStaff] = useState('');
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        window.location.reload();
        setIsOpen(false);
    }

    const getDataByCustomer = (customer_name, c) => {
        setCustomerName(customer_name);
        setCity(c);
        axios.get(`${process.env.REACT_APP_API_URL}inventory/get-inventory/${customer_name}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                setIsOpen(true);
                // console.log(res.data.data);
                const resp = res.data.data
                // console.log(resp.is_hidden);
                setNotes(resp.notes);
                setDate(resp.date);
                setRecentDownload(resp.recent_download);
                setRecentDownloadStaff(resp.recent_download_staff);
                setHidden(resp.is_hidden);
                // setNextDownload(resp.next_download);
                // setNextDownloadStaff(resp.next_download_staff);
            }
        }).catch(err => {
            console.log(err);
            alert('Something went wrong');
        })
    }

    const updateData = () => {
        console.log(hidden);
        axios.put(`${process.env.REACT_APP_API_URL}inventory/update-notes/${customerName}/${city}`,
            {
                notes: notes,
                date: date,
                recent_download: recentDownload,
                recent_download_staff: recentDownloadStaff,
                is_hidden: hidden
                // next_download: nextDownload,
                // next_download_staff: nextDownloadStaff
            }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then(res => {
            setIsOpen(false);
            fetchData();
        }).catch(err => {
            console.log(err);
            alert('Something went wrong');
        })
    }
    return (
        <>
            <Dashboard>
                <div className='flex gap-10 mt-10'>
                    <button className='px-10 py-2 bg-teal-600 focus:bg-black text-white rounded-lg' onClick={() => { fetchData('false') }}>
                        Unhidden Entries
                    </button>
                    <button className='px-10 py-2 bg-teal-600 focus:bg-black text-white rounded-lg' onClick={() => { fetchData('true') }}>
                        Hidden Entries
                    </button>
                    <button className='px-10 py-2 bg-teal-600 focus:bg-black text-white rounded-lg' onClick={() => { fetchData('all') }}>
                        All Entries
                    </button>
                </div>
                <div className="mt-8 relatve overflow-x-auto shadow-md sm:rounded-lg h-[80vh]">
                    <table className="w-full text-gray-500">
                        <thead className="text-xs text-gray-700 bg-gray-50 sticky top-0 z-10">
                            <tr className="text-base text-center text-[10px]">
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    Edit notes
                                </th>
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    <p>Customer Name</p>
                                </th>
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    <p>Notes</p>
                                </th>
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    <p>Date Set/Staff</p>
                                </th>
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    <p>Recent Download</p>
                                </th>
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    <p>Recent Download Staff</p>
                                </th>
                                {/* <th scope="col" className="px-2 py-3">
                                    <p>Next Download</p>
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    <p>Next Download Staff</p>
                                </th>
                                 */}
                                {
                                    inventoryType.map((item, idx) => (
                                        <th scope="col" className="px-1 py-3 text-[10px]" key={idx}>
                                            {item.meter_type}
                                        </th>
                                    ))
                                }
                                <th scope="col" className="px-1 py-3 text-[10px]">
                                    Total
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                inventoryType && inventoryType.length ?
                                    data && data.length ?
                                        <>
                                            {
                                                data.map((item, idx) => (
                                                    <tr className="text-base text-center text-[10px]" key={idx}>
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p className='w-[50px] text-white p-[5px] rounded-lg font-[0] cursor-pointer' onClick={() => { getDataByCustomer(item.customerName, item.city) }}><MdEdit color='blue' /></p>
                                                        </th>
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p>{item.customerName}</p>
                                                        </th>
                                                        <th scope="row" className="px-1 py-3 break-words max-w-[100px] text-[10px]">
                                                            <p>{item.notes}</p>
                                                        </th>
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p>{item.date}</p>
                                                        </th>
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p>{item.recentDownload}</p>
                                                        </th>
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p>{item.recentDownloadStaff}</p>
                                                        </th>
                                                        {/* <th scope="row" className="px-2 py-3">
                                                            <p>{item.nextDownload}</p>
                                                        </th>
                                                        <th scope="row" className="px-2 py-3">
                                                            <p>{item.nextDownloadStaff}</p>
                                                        </th> */}
                                                        {
                                                            inventoryType.map((item2, idx2) => (
                                                                <th scope="row" className="px-1 py-3 text-[10px]" key={idx2}>
                                                                    <p>{item.meters[item2.meter_type] || 0}</p>
                                                                </th>
                                                            ))
                                                        }
                                                        <th scope="row" className="px-1 py-3 text-[10px]">
                                                            <p>{Object.values(item.meters).reduce((sum, value) => sum + value, 0)}</p>
                                                        </th>
                                                    </tr>
                                                ))
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="text-center">
                                                <svg
                                                    role="status"
                                                    className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                            </div>
                                        </>
                                    :
                                    <>
                                        <div className="text-center">
                                            <svg
                                                role="status"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                        </div>
                                    </>
                            }
                            {/* {
                                data.length !== 0 ?
                                    <>
                                        {
                                            data.map((item, idx) => (
                                                <tr className="bg-white border-b " key={idx}>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        <p>{item.customerName}, {item.city}, {item.state}</p>
                                                        <div style={{ background: 'gray', color: 'white', minHeight: 100 }}>
                                                            <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                                                                Notes
                                                                <span><MdEdit onClick={()=>{editNotes(item.id)}}/></span>
                                                            </p>
                                                            <div>
                                                                {
                                                                    !edit ?
                                                                        <div>
                                                                            {item.notes}
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <input value={item.notes} />
                                                                            <button onClick={updateNotes}>Submit</button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        <p>{item.meters['Dent Meters'] || 0}</p>
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        <p>{item.meters['1000 Amps Current Probes'] || 0}</p>
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        <p>{item.meters['Omega Pressure Meters'] || 0}</p>
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['LPT 0-500 psi meters'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['LPT 1000 psi meters'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['LCV Meters'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['L420 Loggers'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Leak Detectors'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Vacuum PT'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Hobo Data Loggers'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Hobo'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Vacuum Pressure Gauges'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {item.meters['Flow Meters'] || 0}
                                                    </th>
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                    >
                                                        {Object.values(item.meters).reduce((sum, value) => sum + value, 0)}
                                                    </th>
                                                </tr>
                                            ))
                                        }
                                    </>
                                    :
                                    <></>
                            } */}
                        </tbody>
                    </table>
                </div>

                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Update Admin Inventory Notes
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div class="grid gap-6 mb-6 lg:grid-cols-2">
                                                <div>
                                                    <label
                                                        for="name"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Notes
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="notes"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Notes"
                                                        value={notes}
                                                        onChange={(e) => { setNotes(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="name"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Date Set/Staff
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="notes"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Date"
                                                        value={date}
                                                        onChange={(e) => { setDate(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="name"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Recent Download
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="notes"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Recent Download"
                                                        value={recentDownload}
                                                        onChange={(e) => { setRecentDownload(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="name"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Recent Download Staff
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="notes"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Recent Download Staff"
                                                        value={recentDownloadStaff}
                                                        onChange={(e) => { setRecentDownloadStaff(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="name"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Hide
                                                    </label>
                                                    <div className='flex gap-4'>
                                                        <input
                                                            type="checkbox"
                                                            checked={hidden}
                                                            class="bg-gray-50 border border-gray-300 p-2.5"
                                                            onChange={(e) => { setHidden(e.target.checked) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                                                // onClick={closeModal}
                                                onClick={updateData}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </Dashboard>
        </>
    )
};