import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import axios from 'axios';
import Dashboard from './Dashboard';
import { BiSort } from "react-icons/bi";

import { MdDelete, MdEdit } from "react-icons/md";
import UploadCSV from '../Components/UploadCSV';
import { NavLink, useNavigate } from 'react-router-dom';
import AddNewInventory from '../Components/AddNewInventory';

const InventoryAdmin = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadMore, setIsLoadMore] = useState(false);

    useEffect(() => {
        // axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-management-data`, {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
        //     }
        // })
        //     .then((res) => {
        //         setPages(res.data.data.totalPages);
        //         setCurrentPage(res.data.data.currentPage);
        //         setData(res.data.data.data.reverse());
        //         //setData(res.data.reverse());
        //         //console.log(res.data);
        //         setLoading(false);
        //     }).catch((err) => {
        //         console.log(err);
        //     })
        fetchData(1);
    }, []);

    const fetchData = (page) => {
        setIsLoadMore(true);
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-management-data?page=${page}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        })
            .then((res) => {
                if (page == 1) {
                    setData(res.data.data.data);
                  } else {
                    setData(item => [...item, ...res.data.data.data]);
                  }
                setPages(Number(res.data.data.totalPages));
                setCurrentPage(Number(res.data.data.currentPage));
                // setData(res.data.data.data);
                setIsLoadMore(false);
                //setData(res.data.reverse());
                //console.log(res.data);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
    }

    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const getSearchTerm = (e) => {
        // searchHandler(e.target.value);
        searchData(e.target.value);
    };

    const searchData = (data) => {
        setSearchTerm(data);
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/search-inventory?query=${data}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                setSearchResult(res.data.data);
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
        })
    }

    const searchHandler = (e) => {
        setSearchTerm(e);
        if (e.length !== "") {
            //console.log(e);
            const newPerformersList = data.filter((performer) => {
                return Object.values(performer)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            });
            setSearchResult(newPerformersList);
            //console.log(newPerformersList);
        } else {
            setSearchResult(data);
        }
    }

    const [filterDate, setFilterDate] = useState(false);
    const dateHandler = (e) => {
        if (filterDate !== true) {
            setSearchResult(data.reverse());
            setFilterDate(true);
        }
        else {
            setData(searchResult.reverse());
            setFilterDate(false);
        }
    }

    const deleteData = (e) => {
        axios.delete(`${process.env.REACT_APP_API_URL}inventoryManagement/delete-inventory/${e}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        })
            .then((res) => {
                alert("Data Deleted Successfully");
                window.location.reload();
            })
            .catch((err) => {
                alert("Error while deleting Data");
            })
    }

    const [assignedToDropdown, setAssignedToDropDown] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}apEnergy/usernames`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data) {
                // console.log(res.data.user);
                setAssignedToDropDown(res.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);

    const [locations, setLocations] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-location`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            // console.log(res);
            if (res && res.data.success) {
                setLocations(res.data.data.sort());
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const assignedToHandler = (e) => {
        setLoading(true);
        if (e.target.value === "select") {
            setSearchResult(data);
            setLoading(false);
        }
        else {
            setSearchTerm(e.target.value)
            // const newPerformersList = data.filter((performer) => {
            //     return Object.values(performer.assigned_to)
            //         .join("")
            //         .toLowerCase()
            //         .includes(e.target.value.toLowerCase());
            // });
            // if (newPerformersList) {
            //     setSearchResult(newPerformersList);
            // }
            // else {
            //     searchResult(data);
            // }
            axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/search-inventory?query=${e.target.value}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
                }
            }).then((res) => {
                if (res && res.data && res.data.success) {
                    setSearchResult(res.data.data);
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
            })
        }
    };

    const locationHandler = (e) => {
        setLoading(true);
        if (e.target.value === "select") {
            setSearchResult(data);
            setLoading(false);
        }
        else {
            setSearchTerm(e.target.value)
            // const newPerformersList = data.filter((performer) => {
            //     return Object.values(performer.city)
            //         .join("")
            //         .toLowerCase()
            //         .includes(e.target.value.toLowerCase());
            // });
            // if (newPerformersList) {
            //     setSearchResult(newPerformersList);
            // }
            // else {
            //     searchResult(data);
            // }

            axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/search-inventory?query=${e.target.value}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
                }
            }).then((res) => {
                if (res && res.data && res.data.success) {
                    setSearchResult(res.data.data);
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    const [cName, setCName] = useState();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/customer-names`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                setCName(res.data.data);
            }
        }).catch(err => {
            setCName([]);
        })
    }, []);

    const customerHandler = (e) => {
        setLoading(true);
        if (e.target.value === "select") {
            setSearchResult(data);
            setLoading(false);
        }
        else {
            setSearchTerm(e.target.value)
            // const newPerformersList = data.filter((performer) => {
            //     return Object.values(performer.customer_name)
            //         .join("")
            //         .toLowerCase()
            //         .includes(e.target.value.toLowerCase());
            // });
            // if (newPerformersList) {
            //     setSearchResult(newPerformersList);
            // }
            // else {
            //     searchResult(data);
            // }

            axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/search-inventory?query=${e.target.value}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
                }
            }).then((res) => {
                if (res && res.data && res.data.success) {
                    setSearchResult(res.data.data);
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    let [isOpen, setIsOpen] = useState(false);

    //add new
    function closeModal() {
        // window.location.reload();
        setLoading(true);
        fetchData(1);
        setIdData([]);
        setIsOpen(false);
    }

    function openModal(id) {
        getDataById(id);
        setIsOpen(true);
    }

    const [idData, setIdData] = useState([]);
    const [aPInventoryBarcode, setAPInventoryBarcode] = useState("");
    const [aPEquipmentIDNumber, setAPEquipmentIDNumber] = useState("");
    const [type, setType] = useState("");
    const [dateTimeOfRecord, setDateTimeOfRecord] = useState(new Date());
    const [assignedTo, setAssignedTo] = useState("");
    const [currentLocation, setCurrentLocation] = useState("");
    const [lastCalibrationDate, setLastCalibrationDate] = useState("");
    const [notes, setNotes] = useState("");
    const [customerName, setCustomerName] = useState("");

    const getDataById = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-data-by-id/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                if (res && res.data.success) {
                    setIdData(res.data.data);
                    setAPInventoryBarcode(res.data.data.ap_inventory_barcode);
                    setAPEquipmentIDNumber(res.data.data.ap_equipment_id_number);
                    setType(res.data.data.type);
                    setDateTimeOfRecord(res.data.data.date);
                    setAssignedTo(res.data.data.assigned_to);
                    setCustomerName(res.data.data.customer_name);
                    setCurrentLocation(res.data.data.city);
                    setLastCalibrationDate(res.data.data.last_calibration_date);
                    setNotes(res.data.data.notes);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // console.log(idData);

    const updateData = (id) => {
        axios.put(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-details-edit/${id}`, {
            _id: id,
            ap_inventory_barcode: aPInventoryBarcode,
            ap_equipment_id_number: aPEquipmentIDNumber,
            type: type,
            date: dateTimeOfRecord,
            assigned_to: assignedTo,
            customer_name: customerName,
            city: currentLocation,
            last_calibration_date: lastCalibrationDate,
            notes: notes
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
            }
        }).then((res) => {
            console.log(res);
            closeModal();
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <Dashboard>
                <div className='flex flex-col md:flex-row gap-4 justify-center items-center mt-2'>
                    <input
                        className='h-12 border-2 border-teal-600 rounded-lg text-center w-5/12'
                        placeholder='Search Here'
                        onChange={getSearchTerm}
                    />

                    {/* <button>Upload CSV Data?</button> */}
                </div>
                <div className='flex flex-col md:flex-row gap-4 justify-center items-center mt-2'>
                    {/* <a href='https://api-ap-d87cea9084ac.herokuapp.com/csv/download-csv' target="_blank">
                        <button
                            type="button"
                            className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25"
                        >
                            Download CSV
                        </button>
                    </a>
                    <UploadCSV /> */}
                    <NavLink to="/inventoryType" className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25">
                        Add Inventory Type
                    </NavLink>
                    <AddNewInventory />
                    <NavLink to="/inventoryByCustomer" className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25">
                        Inventory by Customer
                    </NavLink>
                </div>
                <div className="mt-8 relative overflow-x-auto shadow-md sm:rounded-lg h-[80vh]">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50 sticky top-0 z-10">
                            <tr className="text-base text-center text-[11px]">
                                <th scope="col" className="px-3 py-3">
                                    Edit
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    AP Inventory Barcode
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    AP Equipment ID Number
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Type
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    <div className='flex items-center text-center'>
                                        <span>Date</span><BiSort onClick={dateHandler} />
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Assigned To
                                    <select
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[10px] block p-2.5 mt w-[150px]"
                                        name="IsAnonymous"
                                        onChange={assignedToHandler}
                                        required
                                    >
                                        <option>select</option>
                                        {
                                            assignedToDropdown && assignedToDropdown.length ?
                                                <>
                                                    {
                                                        assignedToDropdown.map((item, index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </select>
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Customer Name
                                    <select
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[10px] block p-2.5 mt w-[150px]"
                                        name="IsAnonymous"
                                        onChange={customerHandler}
                                        required
                                    >
                                        <option>select</option>
                                        {
                                            cName && cName.length ?
                                                <>
                                                    {
                                                        cName.map((item, index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </select>
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    City
                                    <select
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[10px] block p-2.5 mt w-[150px]"
                                        name="IsAnonymous"
                                        onChange={locationHandler}
                                        required
                                    >
                                        <option>select</option>
                                        {
                                            locations && locations.length ?
                                                <>
                                                    {
                                                        locations.map((item, index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </select>
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Notes
                                </th>
                                <th scope="col" className="py-3">
                                    Delete
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Details
                                </th>
                                <th scope="col" className="px-3 py-3">
                                    Last Calibration Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <div className="text-center">
                                        <svg
                                            role="status"
                                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                    </div>
                                    :
                                    <>
                                        {
                                            searchTerm.length < 1 ?
                                                data.map((item, idx) => {
                                                    return (
                                                        <tr className="bg-white border-b text-center cursor-pointer text-[11px]" key={idx}>
                                                            <td className="px-3 py-4">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => { openModal(item._id) }}
                                                                    className="rounded-md text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                >
                                                                    <MdEdit color='blue' />
                                                                </button>
                                                            </td>
                                                            <th
                                                                scope="row"
                                                                className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                            >
                                                                {item.ap_inventory_barcode}
                                                            </th>
                                                            <td className="px-3 py-4">{item.ap_equipment_id_number}</td>
                                                            <td className="px-3 py-4">{item.type}</td>
                                                            <td className="px-3 py-4">{new Date(item.date).toLocaleString()}</td>
                                                            <td className="px-3 py-4">{item.assigned_to}</td>
                                                            <td className="px-3 py-4">{item.customer_name}</td>
                                                            <td className="px-3 py-4">{item.city}</td>
                                                            <td className="px-3 py-4">{item.notes}</td>
                                                            <button
                                                                type="button"
                                                                className="rounded-md py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                                                onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                                            >
                                                                <MdDelete color='blue' />
                                                            </button>
                                                            <td className="px-3 py-4">
                                                                <p className='w-[80px] bg-teal-600 text-white p-[5px] rounded-lg' onClick={() => { navigate(`/inventoryDetail/${item.ap_equipment_id_number}`) }}>View Details</p>
                                                            </td>
                                                            <td className="px-3 py-4">{item.last_calibration_date}</td>
                                                        </tr>
                                                    )
                                                })

                                                :

                                                searchResult.map((item, idx) => {
                                                    return (
                                                        <tr className="bg-white border-b text-center cursor-pointer text-[11px]" key={idx}>
                                                            <td className="px-3 py-4">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => { openModal(item._id) }}
                                                                    className="rounded-md text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                >
                                                                    <MdEdit color='blue' />
                                                                </button>
                                                            </td>
                                                            <th
                                                                scope="row"
                                                                className="px-3 py-4 font-medium text-gray-900  whitespace-nowrap"
                                                            >
                                                                {item.ap_inventory_barcode}
                                                            </th>
                                                            <td className="px-3 py-4">{item.ap_equipment_id_number}</td>
                                                            <td className="px-3 py-4">{item.type}</td>
                                                            <td className="px-3 py-4">{new Date(item.date).toLocaleString()}</td>
                                                            <td className="px-3 py-4">{item.assigned_to}</td>
                                                            <td className="px-3 py-4">{item.customer_name}</td>
                                                            <td className="px-3 py-4">{item.city}</td>
                                                            <td className="px-3 py-4">{item.notes}</td>
                                                            <button
                                                                type="button"
                                                                className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-4"
                                                                onClick={() => { if (window.confirm('Are you sure to delete this record?')) { deleteData(item._id) }; }}
                                                            >
                                                                <MdDelete color='blue' />
                                                            </button>
                                                            <td className="px-3 py-4">
                                                                <p className='w-[80px] bg-teal-600 text-white p-[5px] rounded-lg' onClick={() => { navigate(`/inventoryDetail/${item.ap_equipment_id_number}`) }}>View Details</p>
                                                            </td>
                                                            <td className="px-3 py-4">{item.last_calibration_date}</td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </>
                            }
                        </tbody>
                    </table>
                </div>

                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Update Admin Details
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div class="grid gap-6 mb-6 lg:grid-cols-2">
                                                <div>
                                                    <label
                                                        for="AP Inventory Barcode"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        AP Inventory Barcode
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="AP Inventory Barcode"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="AP Inventory Barcode"
                                                        defaultValue={idData.ap_inventory_barcode}
                                                        onChange={(e) => { setAPInventoryBarcode(e.target.value) }}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        for=" AP Equipment ID Number"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        AP Equipment ID Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id=" AP Equipment ID Number"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                                        placeholder=" AP Equipment ID Number"
                                                        defaultValue={idData.ap_equipment_id_number}
                                                        onChange={(e) => { setAPEquipmentIDNumber(e.target.value) }}
                                                        readOnly
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="Type"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Type
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Type"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                                        placeholder="Type"
                                                        defaultValue={idData.type}
                                                        onChange={(e) => { setType(e.target.value) }}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        for="Date Time of Record"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Date Time of Record
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Date Time of Record"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Date Time of Record"
                                                        defaultValue={idData.date}
                                                        onChange={(e) => { setDateTimeOfRecord(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="Assigned To"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Assigned To
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Assigned To"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Assigned To"
                                                        defaultValue={idData.assigned_to}
                                                        onChange={(e) => { setAssignedTo(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="Current Location"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Customer Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Current Name"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                                        placeholder="Customer Name"
                                                        defaultValue={idData.customer_name}
                                                        onChange={(e) => { setCustomerName(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="Current Location"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        City
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Current Location"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                                        placeholder="Current Location"
                                                        defaultValue={idData.city}
                                                        onChange={(e) => { setCurrentLocation(e.target.value) }}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        for="Last Calibration Date"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Last Calibration Date
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Last Calibration Date"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                                        placeholder="Last Calibration Date"
                                                        defaultValue={idData.last_calibration_date}
                                                        onChange={(e) => { setLastCalibrationDate(e.target.value) }}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        for="Notes"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Notes
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="Notes"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                        placeholder="Notes"
                                                        defaultValue={idData.notes}
                                                        onChange={(e) => { setNotes(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                                                // onClick={closeModal}
                                                onClick={() => { updateData(idData._id) }}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* <div className='flex justify-center gap-12 mt-10 mb-10'>
                    {
                        searchTerm.length < 1 ?
                            <>
                                {
                                    currentPage !== 1 ?
                                        <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage - 1) }}>Prev</button>
                                        :
                                        <></>
                                }
                                {
                                    currentPage !== pages ?
                                        <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage + 1) }}>Next</button>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <>

                            </>
                    }
                </div> */}
                 <div>
                    {
                        currentPage != pages &&
                        <>
                            {
                                !isLoadMore ?
                                    <button className='text-center w-[100%] bg-teal-600 text-white' onClick={() => { fetchData(currentPage + 1) }}>
                                        Load More
                                    </button>
                                    :
                                    <div className="text-center">
                                        <svg
                                            role="status"
                                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                    </div>
                            }
                        </>
                    }
                </div>
            </Dashboard>
        </>
    )
}

export default InventoryAdmin;